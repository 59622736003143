import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { graphql } from "gatsby";
import Section from "../components/Section"
import Feature from "../components/Feature";
import FAQEntry from "../components/FAQEntry";

export default class WLSFaq extends React.Component {
  render() {
    let faqVideo = this.props.data.video;
    let entries = this.props.data.entries.edges.map(({ node }) => node);
    return ( 
      <Layout>
        <Seo title="WLS FAQ" />
        {faqVideo && <Section className="primary" useContainer={false}>
          <Feature content={faqVideo} centerVertically={true} mediaOnly={true} />
        </Section> }
        {entries && <Section className="white" centerHorizontally={true}>
          {entries.map((entry) => {
              return (
                <FAQEntry key={"entry-" + entry.headline} content={entry} />
              );
            })}
        </Section> }
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    video: contentfulVideoResource(slug: { eq: "wls-faq" }) {
      ...VideoResource
    }
    entries: allContentfulFaqEntry(sort: {fields: [displayOrder], order: [ASC]}) {
      edges {
        node {
          ...FAQEntry
        }
      }  
    }
  }
`
