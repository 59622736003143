import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classNames from "classnames";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Headline from "../components/Headline"
import VimeoEmbed from "../components/VimeoEmbed"
import { getLinkForContent } from "../services/utility";

export default class Feature extends React.Component {
  render() {
    let { name, title, vimeoId, cinematicAspectRatio, vimeoThumbnail, playInline, image, description } = this.props.content;
    let bestName = (typeof title !== 'undefined' ? title : name);
    let link = getLinkForContent(this.props.content, this.props.isBonus);
    let  useVimeoEmbed = vimeoId && ((typeof this.props.alwaysPlayInline !== 'undefined' && this.props.alwaysPlayInline === true) || playInline !== false);
    let useLink = ((typeof this.props.useLink === 'undefined' && useVimeoEmbed === false) || this.props.useLink === true);
    let mediaRight = (typeof this.props.mediaRight !== 'undefined' && this.props.mediaRight === true);
    let mediaOnly = (typeof this.props.mediaOnly !== 'undefined' && this.props.mediaOnly === true);
    let rowClasses = classNames(
      (typeof this.props.centerHorizontally === 'undefined' || this.props.centerHorizontally === true) ? "justify-content-center" : null,
      (this.props.centerVertically === true) ? "align-items-center" : null
    );
    return (
      <Container className={classNames("feature", this.props.className)}>
        <Row className={rowClasses}>
          <Col lg={{ span: (mediaOnly ? 12 : 6), order: (mediaRight ? 'last' : 'first') }}>
            { useVimeoEmbed && <VimeoEmbed vimeoId={vimeoId} useCinema={cinematicAspectRatio}></VimeoEmbed> }
            { !useVimeoEmbed && useLink && <Link to={link}>
              { vimeoThumbnail && <GatsbyImage image={getImage(vimeoThumbnail)} alt={bestName} />}
              { !vimeoThumbnail && image && <GatsbyImage image={getImage(image)} alt={bestName} />}
              </Link> }
            { !useVimeoEmbed && !useLink && image && <GatsbyImage image={getImage(image)} alt={bestName} /> }
            { !useVimeoEmbed && !useLink && vimeoThumbnail && <GatsbyImage image={getImage(vimeoThumbnail)} alt={bestName} />}
          </Col>
          { !mediaOnly &&
            <Col lg={{ span: 6, order: (mediaRight ? 'first' : 'last') }}>
              { useLink && <Headline className="pt-3"><Link to={link}>{bestName}</Link></Headline> }
              { !useLink && <Headline className="pt-3">{bestName}</Headline> }
              { (typeof this.props.showDescription === 'undefined' || this.props.showDescription === true) 
                  && description && description.childMarkdownRemark && <div dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
                }}
              />}
            </Col>
          }
        </Row>
      </Container>
    )
  }
}

Feature.propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string,
  useLink: PropTypes.bool,
  alwaysPlayInline: PropTypes.bool,
  showDescription: PropTypes.bool,
  centerHorizontally: PropTypes.bool,
  centerVertically: PropTypes.bool,
  isBonus: PropTypes.bool,
  mediaRight: PropTypes.bool,
  mediaOnly: PropTypes.bool
}

export const featureFragment = graphql`
  fragment Feature on ContentfulMinistryEventContentfulPdfResourceContentfulTopicContentfulVideoResourceContentfulWarriorWorshipSongUnion {
      ...MinistryEvent
      ...Topic
      ...VideoResource
      ...PDFResource
      ...WarriorWorshipSong
    }
`
