import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from "classnames";

export default class FAQEntry extends React.Component {
  render() {
    let { headline, content } = this.props.content;
    return (
      <Container className={classNames(this.props.className)}>
        <Row className="py-1 justify-content-center align-items-center">
          <Col xs={12}>
            <h3>{headline}</h3>
            { content && content.childMarkdownRemark && <div className="content" dangerouslySetInnerHTML={{
              __html: content.childMarkdownRemark.html,
              }}
            />}
          </Col>
        </Row>
      </Container>
    )
  }
}

FAQEntry.propTypes = {
  content: PropTypes.object.isRequired,
  className: PropTypes.string
}


export const faqFragment = graphql`
  fragment FAQEntry on ContentfulFaqEntry {
    headline
    content {
      childMarkdownRemark {
        html
      }
    }
    displayOrder
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`