import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames";

export default class VimeoEmbed extends React.Component {
  render() {
    let cleanId = (this.props.vimeoId && this.props.vimeoId.indexOf("/") > 0) ? this.props.vimeoId.substring(0, this.props.vimeoId.indexOf("/")) : this.props.vimeoId;
    return (
      <div className={classNames(this.props.className, "responsiveVideo", this.props.useCinema ? "aspect241by100" : "aspect16by9")}>
        {cleanId &&
          <iframe title={this.props.title ? this.props.title : "video-" + cleanId} src={"https://player.vimeo.com/video/" + cleanId} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
        }
        {!cleanId &&
          <div className="comingSoonHolder"><div className="comingSoon">COMING SOON&hellip;</div></div>
        }
      </div>
    )
  }
}

VimeoEmbed.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  vimeoId: PropTypes.string,
  useCinema: PropTypes.bool,
}
